<template lang="pug">
  b-card.machines-telemetry-show.card-white.card-no-gutters.shadow(no-body)
    wc-card-tabs(:tabs='tabs')
    b-card-body.mt-3.mt-md-4

      b-row(cols='1' cols-lg='2' cols-xl='3')
        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right.mr-4
                .h1.text-primary
                  | {{ $getDeep(telemetry, 'c.at') || 0 }}
                  span.h6.text-muted
                    |  °C
                .h6.text-primarylight
                  | {{ $t('machine.telemetry.connect') }}
              i.fal.fa-fw.fa-4x.fa-router(:class='connectBoxClass')

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right
                .d-block.d-lg-flex.flex-row
                  .coordinates.mr-4
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'g.la') || 0 }}
                      span.text-muted
                        |  {{ $t('machine.telemetry.lat') }}.
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'g.lo') || 0 }}
                      span.text-muted
                        |  {{ $t('machine.telemetry.lon') }}.
                  .other.mr-4
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'g.al') || 0 }}
                      span.text-muted
                        |  {{ $t('machine.telemetry.alt') }}.
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'g.sp') || 0 }}
                      span.text-muted
                        |  {{ $t('machine.telemetry.speed') }}
                .h6.text-primarylight.mr-4
                  | {{ $t('machine.telemetry.geolocation') }}
              template(v-if='hasCoordinates')
                a(:href='googleMaps' target='_blank')
                  i.fal.fa-fw.fa-4x.fa-satellite.text-secondarylight
              template(v-else)
                i.fal.fa-fw.fa-4x.fa-satellite.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right.mr-4
                .h1.text-primary
                  | {{ $getDeep(telemetry, 'm.ba') || 0 }}
                  span.h6.text-muted
                    |  %
                .h6.text-primarylight
                  | {{ $t('machine.telemetry.battery') }}
              i.fal.fa-fw.fa-4x.fa-car-battery.text-muted

          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-else-if='panelShow && !hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right.mr-4
                .h1.text-primary
                  template(v-if="$getDeep(telemetry, 'm.on')")
                    | {{ k4MotorRange($getDeep(telemetry, 'm.mlr')) }}
                  template(v-else)
                    | 0
                  span.h6.text-muted
                    |  %
                .h6.text-primarylight
                  | {{ $t('machine.telemetry.curtis_l') }}
              i.fal.fa-fw.fa-4x.fa-tire-rugged.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right.mr-4
                .h1.text-primary
                  | {{ decRound($getDeep(telemetry, 'm.op') / 60, 1) || 0 }}
                  span.h6.text-muted
                    |  h
                .h6.text-primarylight
                  | {{ $t('machine.telemetry.operating_hours') }}
              i.fal.fa-fw.fa-4x.fa-stopwatch.text-muted

          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-else-if='panelShow && !hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right.mr-4
                .h1.text-primary
                  template(v-if="$getDeep(telemetry, 'm.on')")
                    | {{ k4MotorRange($getDeep(telemetry, 'm.mrr')) }}
                  template(v-else)
                    | 0
                  span.h6.text-muted
                    |  %
                .h6.text-primarylight
                  | {{ $t('machine.telemetry.curtis_r') }}
              i.fal.fa-fw.fa-4x.fa-tire-rugged.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right.mr-4
                .h6.text-primary
                  | {{ decRound($getDeep(telemetry, 'm.tx') / 100, 1) || 0 }}
                  span.h6.text-muted
                    |  X %
                .h6.text-primary
                  | {{ decRound($getDeep(telemetry, 'm.ty') / 100, 1) || 0 }}
                  span.h6.text-muted
                    |  Y %
                .h6.text-primarylight
                  | {{ $t('machine.telemetry.tilt') }}
              i.fal.fa-fw.fa-4x.fa-car-tilt.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right
                .d-block.d-lg-flex.flex-row.justify-content-end
                  .current.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.flv') / 100, 1) || 0 }}
                      span.h6.text-muted
                        |  V
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.flc') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  A
                  .temperature.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.flmt') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  M. °C
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.flct') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  C. °C
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'm.flr') || 0 }}
                      span.h6.text-muted
                        |  RPM
                .h6.text-primarylight.mr-4
                  | {{ $t('machine.telemetry.curtis_fl') }}
              i.fal.fa-fw.fa-4x.fa-tire-rugged.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right
                .d-block.d-lg-flex.flex-row.justify-content-end
                  .current.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.frv') / 100, 1) || 0 }}
                      span.h6.text-muted
                        |  V
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.frc') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  A
                  .temperature.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.frmt') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  M. °C
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.frct') / 10 , 1)|| 0 }}
                      span.h6.text-muted
                        |  C. °C
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'm.frr') || 0 }}
                      span.h6.text-muted
                        |  RPM
                .h6.text-primarylight.mr-4
                  | {{ $t('machine.telemetry.curtis_fr') }}
              i.fal.fa-fw.fa-4x.fa-tire-rugged.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right
                .d-block.d-lg-flex.flex-row.justify-content-end
                  .current.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.blv') / 100, 1) || 0 }}
                      span.h6.text-muted
                        |  V
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.blc') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  A
                  .temperature.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.blmt') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  M. °C
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.blct') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  C. °C
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'm.blr') || 0 }}
                      span.h6.text-muted
                        |  RPM
                .h6.text-primarylight.mr-4
                  | {{ $t('machine.telemetry.curtis_bl') }}
              i.fal.fa-fw.fa-4x.fa-tire-rugged.text-muted

        b-col.mb-3.pb-3.mb-md-4.pb-md-4
          b-card.lb-dashboard-panel-machines.card-white.card-no-gutters.shadow(
            v-if='panelShow && hasBusData'
            no-body)
            b-card-body.d-flex.justify-content-center.align-items-center
              .text-right
                .d-block.d-lg-flex.flex-row.justify-content-end
                  .current.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.brv') / 100, 1) || 0 }}
                      span.h6.text-muted
                        |  V
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.brc') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  A
                  .temperature.mr-4
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.brmt') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  M. °C
                    .h6.text-primary
                      | {{ decRound($getDeep(telemetry, 'm.brct') / 10, 1) || 0 }}
                      span.h6.text-muted
                        |  C. °C
                    .h6.text-primary
                      | {{ $getDeep(telemetry, 'm.brr') || 0 }}
                      span.h6.text-muted
                        |  RPM
                .h6.text-primarylight.mr-4
                  | {{ $t('machine.telemetry.curtis_br') }}
              i.fal.fa-fw.fa-4x.fa-tire-rugged.text-muted

      //-
        ts": 1625491764,
        "m": { "on": 0, "st": 0 }
</template>

<script>
import Tabs from '@views/container/machines/shared/tabs'
import MachineTelemetry from '@common/machines/telemetry'

export default {
  name: 'machines-telemetry-show',
  mixins: [Tabs, MachineTelemetry],
  computed: {
    panelShow() {
      return this.$getDeep(this.$parent.$parent.$refs, 'machinesPanel.panelShow')
    },
    hasBusData() {
      return this.$getDeep(this.$parent.$parent.$refs, 'machinesPanel.panel.data.attributes.bus')
    },
  },
}
</script>
